<template>
  <section class="invoice-add-wrapper add-news-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <validation-observer ref="rules">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card py-3"
            >
              <!-- Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <b-row>

                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="profile_name"
                      rules="required"
                    >
                      <b-form-group
                        label="Profile Name"
                        label-for="profile_name"
                      >
                        <b-form-input
                          id="profile_name"
                          v-model="profile_name"
                          placeholder="Profile Name"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="company_name"
                      rules="required"
                    >
                      <b-form-group
                        label="Company Name"
                        label-for="company_name"
                      >
                        <b-form-input
                          id="company_name"
                          v-model="company_name"
                          placeholder="Company Name"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="iban_code"
                      rules="required"
                    >
                      <b-form-group
                        label="IBAN Code"
                        label-for="iban_code"
                      >
                        <b-form-input
                          id="iban_code"
                          v-model="iban_code"
                          placeholder="IBAN Code"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="bic_swift"
                      rules="required"
                    >
                      <b-form-group
                        label="BIC/SWIFT"
                        label-for="bic_swift"
                      >
                        <b-form-input
                          id="bic_swift"
                          v-model="bic_swift"
                          placeholder="BIC/SWIFT"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="bank_name"
                      rules="required"
                    >
                      <b-form-group
                        label="Bank Name"
                        label-for="bank_name"
                      >
                        <b-form-input
                          id="bank_name"
                          v-model="bank_name"
                          placeholder="Bank Name"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <validation-provider
                      #default="{ errors }"
                      name="bank_address"
                      rules="required"
                    >
                      <b-form-group
                        label="Bank Address"
                        label-for="bank_address"
                      >
                        <b-form-input
                          id="bank_address"
                          v-model="bank_address"
                          placeholder="Bank Address"
                        />
                      </b-form-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                </b-row>
              </b-card-body>
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >

        <!-- Action Buttons -->
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="sending"
            @click="submitHandler"
          >
            <span v-if="sending">
              <b-spinner
                small
                type="grow"
              />
              Editing...
            </span>
            <span v-else>Edit Bank Profile</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
            @click="cancelEdit()"
          >
            Back
          </b-button>
        </b-card>
        <div class="d-flex justify-content-between align-items-center">
          <label>Set this Banking Profile to default</label>
          <b-form-checkbox
            value="1"
            :checked="role"
            switch
            @change="makeDefault"
          />
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  VBToggle,
  BSpinner,
  BFormCheckbox,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'

import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.min'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BFormCheckbox,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  data() {
    return {
      sending: false,
      id: '',
      profile_name: '',
      company_name: '',
      iban_code: '',
      bic_swift: '',
      bank_name: '',
      bank_address: '',
      role: 0,
    }
  },
  computed: {
    user() {
      return this.$store.state.auth.userData
    },
    banking_profiles() {
      return this.$store.state.billing.allBankingProfiles
    },
  },
  mounted() {
    this.viewBankProfile()
  },
  created() {},
  destroyed() {},
  methods: {
    viewBankProfile() {
      let { id } = this.$route.params
      id = Number(id)

      if (this.banking_profiles === null) this.cancelEdit()

      const bid = this.banking_profiles.findIndex(x => x.id === id)
      const bankProfile = this.banking_profiles[bid]

      if (!(typeof (bankProfile) === 'undefined' || bankProfile === null)) {
        this.id = bankProfile.id
        this.profile_name = bankProfile.profile_name
        this.company_name = bankProfile.company_name
        this.iban_code = bankProfile.iban_code
        this.bic_swift = bankProfile.bic_swift
        this.bank_name = bankProfile.bank_name
        this.bank_address = bankProfile.bank_address
        this.role = bankProfile.role
      } else {
        this.cancelEdit()
      }
    },
    submitHandler() {
      this.$refs.rules.validate().then(success => {
        if (success) {
          this.sending = true
          const payload = {
            id: this.id,
            profile_name: this.profile_name,
            company_name: this.company_name,
            iban_code: this.iban_code,
            bic_swift: this.bic_swift,
            bank_name: this.bank_name,
            bank_address: this.bank_address,
            role: this.role,
          }

          this.$store.dispatch('billing/editBankingProfile', payload)
            .then(() => {
              this.sending = false
              this.notify({
                text: 'Bank profile has been edited successfully!',
                variant: 'success',
              })
            })
            .then(() => {
              this.$router.push('/billing/banking-profiles').catch(error => { console.log(error) })
            })
        }
      })
    },
    cancelEdit() {
      this.$router.push('/billing/banking-profiles').catch(error => { console.log(error) })
    },
    makeDefault() {
      if (this.role) this.role = 0
      else this.role = 1
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.add-news-wrapper .ql-editor {
  min-height: 320px;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

</style>
